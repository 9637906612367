import { render, staticRenderFns } from "./ShuoMing.vue?vue&type=template&id=96eca082&scoped=true&"
import script from "./ShuoMing.vue?vue&type=script&lang=js&"
export * from "./ShuoMing.vue?vue&type=script&lang=js&"
import style0 from "./ShuoMing.vue?vue&type=style&index=0&id=96eca082&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96eca082",
  null
  
)

export default component.exports