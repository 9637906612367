<template>
    <div>

      <el-input v-if="type === 1" style="width: 200px;margin-right: 20px;margin-bottom: 20px" v-model="like" placeholder="账号或应用名称或id"></el-input>
      <el-input v-if="type !== 1" style="width: 200px;margin-right: 20px;margin-bottom: 20px" v-model="like" placeholder="应用名称"></el-input>
      <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>

      <el-table
                :data="tableData"
                border
                style="width: 100%;"
                :header-cell-style="headClass"
                :cell-style="rowClass">
            <el-table-column
                    prop="id"
                    label="id" style="text-align: center">
            </el-table-column>
            <el-table-column
                    prop="account"
                    label="账号">
            </el-table-column>
            <el-table-column
                    prop="certName"
                    label="证书名">
            </el-table-column>
            <el-table-column
                    prop="createTime"
                    label="签名时间">
            </el-table-column>
            <el-table-column
                    prop="appName"
                    label="app名">
            </el-table-column>
            <el-table-column
                    prop="pageName"
                    label="包名">
            </el-table-column>
            <el-table-column
                    prop="version"
                    label="版本">
            </el-table-column>
            <el-table-column
                label="时间锁">
                <template slot-scope="scope">
                  <div >{{scope.row.isTimeLock === 1 ?'是':'否'}}</div>
                </template>
            </el-table-column>

            <el-table-column
                prop="lockTimeFinish"
                label="到期时间">

            </el-table-column>

            <el-table-column
                    prop="status"
                    label="状态">
            </el-table-column>

          <el-table-column
              prop="remark"
              label="备注">
              <template slot-scope="scope">
                {{scope.row.remark}}
              </template>
          </el-table-column>


            <el-table-column
                    prop="downUrl"
                    label="操作">
                <template slot-scope="scope">
                    <el-button  v-if="scope.row.downUrl != null" @click="down(scope.row.downUrl)" type="text" size="small">下载Ipa</el-button>
                    <el-button  v-if="scope.row.isTimeLock === 1" @click="updateTime(scope.$index)" type="text" size="small">修改时间锁</el-button>
                    <el-button   @click="deleteIpa(scope.row.id)" type="text" size="small">删除</el-button>
                    <el-button   @click="resignF(scope.row.id,false)" type="text" size="small">重签</el-button>
                    <el-button   @click="copy(scope.row.downUrl)" type="text" size="small">复制下载地址</el-button>
                  <el-button type="text" size="small"   @click="updateRemark(scope.row.id,scope.row.remark)" >编辑备注</el-button>
                </template>
            </el-table-column>



        </el-table>


      <el-dialog
          title="修改时间"
          :visible.sync="updateTimeDg"
          center
          width="30%">
        <el-date-picker
            v-model="updateTimeV"
            type="datetime"
            placeholder="到期时间"
        >
        </el-date-picker>
        <p></p>
        <el-button @click="updateTimeDg = false">取 消</el-button>
        <el-button type="primary" @click="updateLock()">确 定</el-button>
      </el-dialog>



      <el-dialog
          title="选择证书"
          :visible.sync="resignDg"
          center
          width="30%">
        <el-select v-model="value" placeholder="请选择">
          <el-option
              v-for="item in certs"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <p></p>
        <el-button @click="resignDg = false">取 消</el-button>
        <el-button type="primary" @click="resignF(0,true)">确 定</el-button>
      </el-dialog>


      <el-dialog
          title="备注编辑"
          :visible.sync="dialogVisible3"
          center
          width="30%">
        <el-input
            maxlength="100"
            type="textarea"
            :rows="3"
            placeholder="请输入备注,100字以内"
            v-model="remark">
        </el-input>
        <p></p>
        <el-button @click="dialogVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="updateRemarkPost()">确 定</el-button>
      </el-dialog>


      <el-pagination
                style="text-align: center;margin-top: 20px"
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[5, 40, 80, 100]"
                :page-size="count"
                layout="total, sizes, prev, pager, next, jumper"
                :page-count="pages"
                :total="total">
        </el-pagination>


    </div>
</template>

<script>
    export default {
        created() {
            const user = JSON.parse(window.localStorage.getItem('user'))
            console.log(user)
            this.account = user.account
            this.type = user.type
            this.update(this.page,this.count);
            if(user.type === 0){
                this.admin = false
            }else {
                this.admin = true
            }
          this.getCertS();
        },
        methods: {
          updateRemarkPost(){
            this.dialogVisible3 = false
            var that = this
            this.$http.post('/EnterpriseSign/updateRemark?id=' + that.id3 + "&remark=" + that.remark)
                .then(function (response) {
                  that.$load(0)
                  if(response.data.code == 0){
                    that.update(that.page,that.count);
                    that.$message.success(response.data.message)
                  }else {
                    that.$message.error(response.data.message)
                  }
                })
                .catch(function (error) {
                  that.$load(0)
                  that.$message.error("修改失败")
                });
          },
          //修改简介
          updateRemark(id,remark){
            this.dialogVisible3 = true
            console.log(remark)
            this.remark = remark
            this.id3 = id
          },
          copy(url){
            var oInput = document.createElement('input'); //创建一个隐藏input（重要！）
            oInput.value = url;    //赋值
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象
            document.execCommand("Copy"); // 执行浏览器复制命令
            oInput.className = 'oInput';
            oInput.style.display = 'none';
            this.$message.success('复制成功');
          },
          resignF(id,status){
            var that = this
              if(status){

                if(this.value === ""){
                   that.$message.error("请选择证书")
                   return;
                }


                this.$http.post('/EnterpriseSign/resign','id=' + this.resignSelectIndex + "&certId=" + this.value)
                    .then(function (response) {
                      that.$load(0)
                      if(response.data.code === 0){
                        that.update(that.page,that.count);
                        that.$message.success(response.data.message)
                        that.resignDg = false
                      }else {
                        that.$message.error(response.data.message)
                      }
                    })
                    .catch(function (error) {
                      that.$load(0)
                      that.$message.error("失败")

                    }).finally(function (){

                });


              }else {
                this.value = ""
                this.resignDg = true
                this.resignSelectIndex = id
              }
          },
          getCertS(){
            var that = this
            this.$http.get('/EnterpriseSign/queryAllCert?pageNum=' + 1 + '&pageSize=' + 50000)
                .then(function (response) {
                  if(response.data.code == 0){
                    console.log(response.data.data)
                    that.certs = response.data.data
                    console.log("证书列表")
                    console.log(that.certs)
                  }else {
                    that.$message.error(response.data.message)
                  }
                })
                .catch(function (error) {
                  that.$load(0)
                  that.$message.error("查询失败")
                });
          },
          deleteIpa(id){
            var that = this

            this.$http.post('/EnterpriseSign/delete','id=' + id)
                .then(function (response) {
                  that.$load(0)
                  if(response.data.code === 0){
                    that.update(that.page,that.count);
                    that.$message.success(response.data.message)
                  }else {
                    that.$message.error(response.data.message)
                  }
                })
                .catch(function (error) {
                  that.$load(0)
                  that.$message.error("删除失败")

                }).finally(function (){

            });
          },
          search(){
            this.count = 5;
            this.page = 1;
            this.pages = 0;
            this.total = 0;
            this.update(this.page,this.count);
          },
          formatDateTime (date) {
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            var h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            var minute = date.getMinutes();
            minute = minute < 10 ? ('0' + minute) : minute;
            var second = date.getSeconds();
            second = second < 10 ? ('0' + second) : second;
            return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
          },
            updateLock(){
              console.log("修改时间")
              console.log(this.updateTimeV)

              var that = this

              this.$http.post('/EnterpriseSign/update_time_lock','id=' + this.tableData[this.selectIndex].id + "&lockTimeFinish=" + this.formatDateTime(this.updateTimeV))
                  .then(function (response) {
                    that.$load(0)
                    if(response.data.code == 0){
                      that.update(that.page,that.count);
                      that.$message.success(response.data.message)
                    }else {
                      that.$message.error(response.data.message)
                    }
                  })
                  .catch(function (error) {
                    that.$load(0)
                    that.$message.error("修改失败")

                  }).finally(function (){
                that.updateTimeDg = false
              });
            },
            updateTime(index){
              console.log("更新" +index)
              this.updateTimeV = new Date(this.tableData[index].lockTimeFinish)
              this.updateTimeDg = true
              this.selectIndex = index;
            },
            down(url){
                console.log(url)
                window.location.href = url
            },
            handleSizeChange(val) {
                console.log("每页数量" + val)
                this.count = val
                this.update(this.page,this.count);
            },
            handleCurrentChange(val) {
                this.page = val
                this.update(this.page,this.count);
                console.log(`当前页: ${val}`);
            },
            update(pageNum,pageSize){
                var that = this
                that.$load(1,"查询中")
                this.$http.get('/EnterpriseSign/queryAccountPack?pageNum=' + pageNum + '&pageSize=' + pageSize + "&like=" + this.like)
                    .then(function (response) {
                        that.$load(0)
                        if(response.data.code == 0){
                            console.log(response.data.data)
                            that.tableData = response.data.data
                            that.pages = response.data.pages
                            that.total = response.data.total
                        }else {
                            that.$message.error(response.data.message)
                        }
                    })
                    .catch(function (error) {
                        that.$load(0)
                        that.$message,error("查询失败")
                    });
            },
            headClass() { //表头居中显示
                return "text-align:center"
            },
            rowClass() { //表格数据居中显示
                return "text-align:center"
            }
        },
        data() {
            return {
              dialogVisible3:false,
              remark: '',
              id3: 0,
                certs: [],
                resignDg: false,
                resignSelectIndex: 0,
                resignV: "获取中",
                selectIndex: 0,
                tableData: [],
                count: 5,
                page: 1,
                pages: 0,
                total: 1,
                admin:false,
                lockFinishTimes: [],
                updateTimeDg: false,
                updateTimeV: Date(),
                account:'',
                type:'',
                like:'',
                value: ''
            }
        }
    }
</script>

<style lang="less" >
    .el-pagination{
        .el-input__inner{
            height: 30px;
        }
    }
</style>
