<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>扣量设置</span>
        <el-button style="float: right;  padding: 3px 0" :disabled="disabled" @click="upload"  type="text">{{buttonText}}</el-button>

      </div>
      <el-form  label-width="300px" >

        <el-tag>分发设置</el-tag>
        <el-form-item label="mdm企业签名安装1次设备扣除(/台)" >
          <el-input v-model="req.mdmSoftTotal" style="width: 200px;" type="number" ></el-input>
        </el-form-item>

        <el-form-item label="mdm企业签名设备重复下载几次继续扣费" >
          <el-input v-model="req.mdmDeviceCount" style="width: 200px;" type="number" ></el-input>
        </el-form-item>

        <el-form-item label="mdm超级签名下载1次扣除(/台)" >
          <el-input v-model="req.mdmSuperTotal" style="width: 200px;" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="超级签名分发下载1次扣除(/台)" >
          <el-input v-model="req.superTotal" style="width: 200px;" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="单点分发下载1次扣除(/台)" >
          <el-input v-model="req.oneSuperTotal" style="width: 200px;" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="自助分发扣除(/台)" >
          <el-input v-model="req.softTotal" style="width: 200px;" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="免签封装1次扣除(/台)" >
          <el-input v-model="req.webPackTotal" style="width: 200px;" type="number" ></el-input>
        </el-form-item>


        <el-tag>虚拟扣量设置</el-tag>
        <el-form-item label="mdm企业签名第几次安装设备触发扣量" >
          <el-input v-model="req.mdmSoftNum" style="width: 200px;margin-right: 30px" type="number" ></el-input>
          额外扣除数量
          <el-input v-model="req.mdmSoftReCount" style="width: 200px;margin-left: 10px" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="mdm超级签名第几次触发扣量" >
          <el-input v-model="req.mdmSuperNum" style="width: 200px;margin-right: 30px" type="number" ></el-input>
          额外扣除数量
          <el-input v-model="req.mdmSuperReCount" style="width: 200px;margin-left: 10px" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="超级签名第几次触发扣量" >
          <el-input v-model="req.superNum" style="width: 200px;margin-right: 30px" type="number" ></el-input>
          额外扣除数量
          <el-input v-model="req.superReCount" style="width: 200px;margin-left: 10px" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="自助分发第几次触发扣量" >
          <el-input v-model="req.softNum" style="width: 200px;margin-right: 30px" type="number" ></el-input>
          额外扣除数量
          <el-input v-model="req.softReCount" style="width: 200px;margin-left: 10px" type="number" ></el-input>
        </el-form-item>


        <el-tag>其他设置</el-tag>
        <el-form-item label="后台域名" >
          <el-input v-model="req.mainDomain" style="width: 200px;margin-right: 30px" type="text" ></el-input>
        </el-form-item>
        <el-form-item label="mdm域名" >
          <el-input v-model="req.mdmDomain" style="width: 200px;margin-right: 30px" type="text" ></el-input>
        </el-form-item>
        <el-form-item label="下载域名" >
          <el-input v-model="req.downDomain" style="width: 200px;margin-right: 30px" type="text" ></el-input>
        </el-form-item>
        <el-form-item label="mdm签名证书ID" >
          <el-input v-model="req.mdmCertId" style="width: 200px;margin-right: 30px" type="text" ></el-input>
        </el-form-item>
        <el-form-item label="企业签名证书ID" >
          <el-input v-model="req.qiyeCertId" style="width: 200px;margin-right: 30px" type="text" ></el-input>
        </el-form-item>
        <el-form-item label="是否开放注册 1是 0否" >
          <el-input v-model="req.openReg" style="width: 200px;margin-right: 30px" type="text" ></el-input>
        </el-form-item>
        <el-form-item label="客户屏蔽功能列表" >
          <textarea style="width: 500px;margin-right: 30px" v-model="req.noDisplay"></textarea>
          <div>说明: 请务必以英文逗号分隔,列表名字需要完全一致区分大小写且无空格</div>
        </el-form-item>


        <el-tag>阿里云OSS储存设置</el-tag>
        <el-form-item label="aliAccesskey" >
          <el-input v-model="req.aliAccesskey" placeholder="阿里云Accesskey" style="width: 400px;margin-right: 30px" type="text" ></el-input>
        </el-form-item>
        <el-form-item label="aliSecretkey" >
          <el-input v-model="req.aliSecretkey" placeholder="阿里云Secretkey"  style="width: 400px;margin-right: 30px" type="text" ></el-input>
        </el-form-item>
        <el-form-item label="aliBucket" >
          <el-input v-model="req.aliBucket"  placeholder="阿里云OSS Bucket 名称"  style="width: 400px;margin-right: 30px" type="text" ></el-input>
        </el-form-item>
        <el-form-item label="阿里云OSS Endpoint 地域节点" >
          <el-input v-model="req.aliEndpoint" placeholder="如 https://oss-cn-hangzhou.aliyuncs.com" style="width: 400px;margin-right: 30px" type="text" ></el-input>
        </el-form-item>
        <el-form-item label="阿里云OSS Bucket域名" >
          <el-input v-model="req.aliBucketDomain" placeholder="如https://xxxx.oss-cn-hangzhou.aliyuncs.com/" style="width: 400px;margin-right: 30px" type="text" ></el-input>
        </el-form-item>



        <!--      <el-tag>七牛云储存设置 (未测试)</el-tag>-->
        <!--      <el-form-item label="qiniuAccesskey" >-->
        <!--        <el-input v-model="req.qiniuAccesskey" style="width: 400px;margin-right: 30px" type="text" ></el-input>-->
        <!--      </el-form-item>-->
        <!--      <el-form-item label="qiniuSecretkey" >-->
        <!--        <el-input v-model="req.qiniuSecretkey" style="width: 400px;margin-right: 30px" type="text" ></el-input>-->
        <!--      </el-form-item>-->
        <!--      <el-form-item label="Bucket" >-->
        <!--        <el-input v-model="req.qiniuBucket" style="width: 400px;margin-right: 30px" type="text" ></el-input>-->
        <!--      </el-form-item>-->
        <!--      <el-form-item label="Bucket域名 如https://www.xxx.com" >-->
        <!--        <el-input v-model="req.qiniuUrl" style="width: 400px;margin-right: 30px" type="text" ></el-input>-->
        <!--      </el-form-item>-->
        <!--      <el-form-item label="内网地区 huadong 或者 huabei 不填则不用内网" >-->
        <!--        <el-input v-model="req.qiniuReg" style="width: 400px;margin-right: 30px" type="text" ></el-input>-->
        <!--      </el-form-item>-->

      </el-form>
    </el-card >
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>描述文件签名证书</span>
        <el-button style="float: right;  padding: 3px 0"  @click="update"  type="text">更新</el-button>

      </div>
      <el-form  label-width="100px" >

        <el-form-item label="pem:" prop="startIcon">
          <input class="file"  name="fileStartIcon" type="file" accept=".pem" @change="pemF($event)"/>
        </el-form-item>

        <el-form-item label="key:" prop="startIcon">
          <input class="file"  name="fileStartIcon" type="file" accept=".key" @change="keyF($event)"/>
        </el-form-item>

      </el-form>
    </el-card >

<!--    <el-card class="box-card">-->
<!--      <div slot="header" class="clearfix">-->
<!--        <span>系统更新</span>-->
<!--        <el-button style="float: right;  padding: 3px 0"  @click="updateSys"  type="text">更新</el-button>-->

<!--      </div>-->
<!--      <el-form  label-width="100px" >-->


<!--        <el-form-item label="静态文件压缩包:" prop="startIcon">-->
<!--          <input class="file"  name="fileStartIcon" type="file" accept=".zip" @change="zipF($event)"/>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="系统jar包:" prop="startIcon">-->
<!--          <input class="file"  name="fileStartIcon" type="file" accept=".jar" @change="signjarF($event)"/>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="mdmjar包:" prop="startIcon">-->
<!--          <input class="file"  name="fileStartIcon" type="file" accept=".jar" @change="mdmjarF($event)"/>-->
<!--        </el-form-item>-->

<!--      </el-form>-->
<!--      <el-form-item label="">-->
<!--        <el-progress v-if="percentage === 0? false:true" :text-inside="true" :stroke-width="25" :percentage="percentage"></el-progress>-->
<!--      </el-form-item>-->

<!--    </el-card >-->

  </div>

</template>

<script>
export default {
  name: "UploadCert",
  created() {
    this.query();
  },
  data(){
    return {
      percentage: 0,
      pem:'',
      key:'',
      zip: '',
      signjar: '',
      mdmjar: '',
      req:{
        aliAccesskey: '',
        aliSecretkey: '',
        aliBucket: '',
        aliEndpoint: '',
        aliBucketDomain: '',
        qiniuAccesskey: '',
        qiniuSecretkey: '',
        qiniuBucket: '',
        qiniuUrl: '',
        qiniuReg: '',
        noDisplay: '',
        openReg: 1,
        mainDomain: "www.xxx.com",
        mdmCertId: "",
        qiyeCertId: "",
        mdmDomain: "www.xxx.com",
        downDomain: "www.xxx.com",
        mdmSoftNum: 0,
        mdmSoftReCount: 0,
        mdmSuperNum: 0,
        mdmSuperReCount: 0,
        superNum: 0,
        superReCount: 0,
        softNum: 0,
        softReCount: 0,
        superTotal: 0,
        mdmSuperTotal: 0,
        softTotal: 0,
        mdmSoftTotal: 0,
        webPackTotal: 0,
        oneSuperTotal: 0,
        mdmDeviceCount: 0
      },
      disabled:false,
      buttonText:"保存",
    }
  },
  methods:{
    zipF(event){
      this.zip = event.target.files[0]
    },
    signjarF(event){
      this.signjar = event.target.files[0]
    },
    mdmjarF(event){
      this.mdmjar = event.target.files[0]
    },
    pemF(event){
      this.pem = event.target.files[0]
    },
    keyF(event){
      this.key = event.target.files[0]
    },
    query(){
      var  that = this

      that.$http.post("/admin/system_settings_query").then(function (result) {
        that.$load(0)
        if(result.data.code ===  0){
          that.req = result.data.data
        }else {
          that.$message.error(result.data.message)
        }
      }).catch(function (err) {
        that.$load(0)
        console.log(err)
      })
    },
    updateSys(){
      const that = this;
      if(this.zip === "" && this.signjar === "" && this.mdmjar === ""){
        that.$message.error("必须填写其中一项")
      }else {
        that.$load(1,"更新中")
        var config = {
          onUploadProgress:function(progressEvent){
            let complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
            that.percentage = complete;

          }
        };
        let formData = new FormData();
        formData.append("html", this.zip);
        formData.append("signjar", this.signjar);
        formData.append("mdmjar", this.mdmjar);
        that.$http.post("/admin/updateSystem",formData,config).then(function (result) {
          if(result.data.code ===  0){
            that.$load(0)
            that.$message.success(result.data.message)
          }else {
            that.$load(0)
            that.$message.error(result.data.message)
          }
        }).catch(function (err) {
          that.$load(0)
        })

      }
    },
    update(){
      const that = this;

      if(this.pem === ""  || this.key === "" ){
        that.$message.error("请填写完整信息!")
      }else {
        that.$load(1,"更新中")

        let formData = new FormData();
        formData.append("pem", this.pem);
        formData.append("key", this.key);
        that.$http.post("/admin/updateCert",formData).then(function (result) {
          if(result.data.code ===  0){
            that.$load(0)
            that.$message.success(result.data.message)
          }else {
            that.$load(0)
            that.$message.error(result.data.message)
          }
        }).catch(function (err) {
          that.$load(0)
        })

      }
    },
    upload(){
      const that = this;
      that.disabled = true
      that.buttonText = '保存中'
      that.$http.post("/admin/system_settings",that.req).then(function (result) {
        console.log(result.data)
        that.disabled = false
        that.buttonText = '保存'
        if(result.data.code ===  0){

          that.$message.success(result.data.message)
        }else {
          that.$message.error(result.data.message)
        }
      }).catch(function (err) {
        that.disabled = false
        that.buttonText = '保存'
        console.log(err)
      })

    }
  }
}
</script>

<style scoped>

</style>
