<template>
  <div>

  </div>
</template>

<script>
    export default {
        name: 'Skip',
        data() {
            return {

            }
        },
        beforeCreate() {

        },
        created() {
          var that = this
          this.init()
        },
      methods:{
            async init(){
              var that = this
             await this.$http.get('/api/setting')
                  .then(function (response) {
                    if(response.data === "" || response.data === window.location.host || response.data === "www.xxx.com"){
                      that.$router.push("/login")
                    }
                  })
                  .catch(function (error) {

                  });
            }
        }
    }
</script>

<style lang="less" scoped>

    .box{
        background-color: #2b4b6b;
        height: 100%;
        width: 100%;
    }

    .login_box {
        width: 450px;
        height: 300px;
        background-color: #ffffff;
        border-radius: 3px;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .avatar_box {
            height: 100px;
            width: 100px;
            border: 1px solid #dddddd;
            border-radius: 50%;
            padding: 10px;
            box-shadow: 0 0 10px #dddddd;
            position: relative;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #ffffff;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: #eeeeee;
            }
        }
    }

    .login_from {
        position: absolute;
        bottom: 20px;
        width: 100%;
        padding: 0 50px;
        box-sizing: border-box;
    }

    .btns {
        margin-top: 30px;
        margin-left: 15%;

        .btns_reg {
            width: 100px;
            margin-left: 60px;
        }

        .btns_log {
            width: 100px;
        }
    }

</style>
